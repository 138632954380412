<template>
  <b-modal v-model="modalVisible" id="add-student-modal" size="lg" centered v-bind:title="this.$t('mentorship.addStudent')" header-class="rm-panel-heading">
    <div class="row">
      <label class="col-form-label col-md-3 text-lg-right">
        {{ this.$t("mentorship.enterStudentUniqueCode") }}
        <span class="text-theme">*</span>
      </label>
      <div class="col-md-6">
        <b-input type="text" v-model="upperCaseStudentCode" class="form-control" minlength="6" maxlength="6" size="6" placeholder="XXXXXX"/>
        <small>{{ this.$t("mentorship.uniqueCodeExplanation") }}</small>
      </div>
    </div>
    <div v-if="errorAdding" class="row m-t-20">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <div class="alert alert-danger" role="alert">
          <i class="fa fa-exclamation-triangle m-r-5" aria-hidden="true"></i>
          <b>{{ $t("mentorship.errorAddingStudent")}}: </b>
          {{ userError }}
        </div>
      </div>
    </div>
    <div v-if="successAdding" class="row m-t-20">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <div class="alert alert-success text-center" role="alert">
          <i class="fa fa-check m-r-5" aria-hidden="true"></i>
          <span>{{ $t("mentorship.studentAdded") }}</span>
        </div>
      </div>
    </div>
    <template #modal-footer>
      <b-button variant="primary" :disabled="studentCode.length !== 6 || forceDisableButton || saving" @click="handleSubmit">{{ $t("mentorship.add") }}</b-button>
      <b-button variant="secondary" :disabled="saving" @click="handleClose">{{ $t("mentorship.cancel") }}</b-button>
    </template>
  </b-modal>
</template>

<script>
import {mentorshipService} from "@/_services/mentorship.service";

export default {
  name: "NewStudentModal",
  data() {
    return {
      modalVisible: false,
      studentCode: "",
      errorAdding: false,
      successAdding: false,
      saving: false,
      error: "",
      // if error, disable button until user does not change student code
      forceDisableButton: false,
    }
  },
  computed: {
    upperCaseStudentCode: {
      get() {
        return this.studentCode.toUpperCase();
      },
      set(value) {
        this.studentCode = value;
        if(this.forceDisableButton) {
          this.forceDisableButton = false;
        }
      },
    },
    userError() {
      return this.$t("mentorship." + this.error);
    }
  },
  methods: {
    initData(savingValue) {
      this.studentCode = "";
      this.errorAdding = false;
      this.successAdding = false;
      this.forceDisableButton = false;
      this.error = ""
      this.saving = savingValue;
    },
    handleClose() {
      this.modalVisible = false;
      this.initData(false);
    },
    handleSubmit() {
      this.errorAdding = false;
      this.saving = true;

      mentorshipService.addMyStudent({studentCode: this.upperCaseStudentCode})
        .then(() => {
          this.successAdding = true;
          this.$emit("reloadStudents");
          return new Promise(resolve => {
            setTimeout(() => {
              resolve();
            }, 1000);
          });
        })
        .then(() => {
          // do not set saving to false (we don't want to enable buttons since popup will close in any way)
          this.modalVisible = false;
          this.initData(true);
        })
        .catch((error) => {
          this.saving = false;
          this.errorAdding = true;
          this.forceDisableButton = true;
          this.error = error;
        });
    },
  },
  created() {
    this.initData(false);
  }
}
</script>