<template>
  <div>
    <RMPageHeader>
      <template v-slot:header>
        {{ $t('mentorship.myStudents') }}
      </template>
    </RMPageHeader>
    <div class="row justify-content-end m-b-10">
      <b-button v-b-modal="'add-student-modal'" variant="primary" class="m-r-10"><i class="fa fa-plus m-r-5" aria-hidden="true"></i>{{ this.$t("mentorship.addStudent") }}</b-button>
    </div>
    <hr/>
    <div v-if="loadingStudents" class="row m-t-20">
      <div class="col-12">
        <b-skeleton class="height-200"></b-skeleton>
      </div>
    </div>
    <div v-else-if="myStudents.length === 0" class="row m-t-20">
      <div class="col-12">
        <b-alert show variant="light" class="text-center" style="font-size: medium">
          {{ $t("mentorship.noStudentsYet") }}
        </b-alert>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-xl-4 col-xxl-3 col-xxxl-3"
        v-for="(myStudent, idx) in myStudents"
        :key="idx"
      >
        <RMPanel :title="myStudent.name">
          <template v-slot:panelBody>
            <div class="d-flex justify-content-between m-b-10">
              <span>{{ $t("mentorship.studentInfo.email") }}:</span>
              <div>
                <b>{{ myStudent.email }}</b>
                <RMEmail :e-href="getEmailHref(myStudent)"/>
              </div>
            </div>
            <hr/>
            <div class="d-flex justify-content-between m-b-10">
              <span>{{ $t("assignments.numberOfAssignments") }}:</span>
              <div>
                <b>{{ myStudent.numberOfAssignments }}</b>
              </div>
            </div>
            <hr/>
            <div class="d-flex justify-content-end">
              <b-link @click="navigateToMyStudentPage(myStudent.id)">{{ $t("assignments.detailsLink") }}</b-link>
            </div>
          </template>
        </RMPanel>
      </div>
    </div>
    <NewStudentModal @reloadStudents="loadMyStudents"/>
  </div>
</template>

<script>

import RMPageHeader from "@/components/common/RMPageHeader.vue";
import {mentorshipService} from "@/_services/mentorship.service";
import {handleError} from "@/_helpers/api";
import router from "@/config/PageRoutes";
import NewStudentModal from "@/pages/mentorship/NewStudentModal.vue";
import RMPanel from "@/components/common/RMPanel.vue";
import RMEmail from "@/components/common/small/RMEmail.vue";
import {getMyStudentRoute} from "@/_helpers/routes_helper";

export default {
  name: "MyStudents",
  data() {
    return {
      loadingStudents: false,
      myStudents: []
    }
  },
  components: {RMEmail, RMPanel, NewStudentModal, RMPageHeader},
  methods: {
    getEmailHref(myStudent) {
      return `mailto:${myStudent.email}`
    },
    navigateToMyStudentPage(userId) {
      this.$router.push(getMyStudentRoute(userId));
    },
    loadMyStudents() {
      this.loadingStudents = true;

      mentorshipService.listMyStudents().then((data) => {
        this.myStudents = data.data;
        this.loadingStudents = false;
      }).catch((error) => handleError(error, router, this._vm)).finally(() => {
        this.loadingStudents = false;
      })
    }
  },
  created() {
    this.loadMyStudents();
  },
}

</script>